@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@500&family=Edu+TAS+Beginner:wght@600;700&display=swap');

/*
	Minimaxing by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	-webkit-text-size-adjust: none;
}

mark {
	background-color: transparent;
	color: inherit;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input, select, textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
}

/* Basic */

	html {
		box-sizing: border-box;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	@-ms-viewport {
		width: device-width;
	}

	body {
		font-size: 13.5pt;
		font-family: sans-serif;
		background-color: #e3e9dc;
		font-family: 'Baloo 2', sans-serif;
		color: #878e83;
		letter-spacing: -1px;
	}

	h1, h2, h3, h4 {
		color: #007897;
		font-weight: normal;
	}

	h2 {
		font-size: 1.6em;
		letter-spacing: -1px;
		margin-bottom: 1em;
	}

	h3, h4 {
		color: #283121;
		margin-bottom: 0.5em;
	}

	h3 {
		font-size: 1.2em;
		margin-bottom: 0.8em;
	}

	h4 {
		font-size: 1em;
	}

	p, ul {
		margin-bottom: 1.25em;
	}

	p {
		line-height: 1.8em;
	}

	strong {
		color: #474e43;
	}

	img.left {
		float: left;
		margin: 2px 1.25em 0 0;
	}

	img.top {
		margin: 4px 0 2.25em 0;
	}

	ul {
		padding: 0.25em 0 0 0;
	}

	a {
		color: #5d93a2;
		text-decoration: underline;
		font-weight : bold;
	}

		a:hover {
			text-decoration: none;
		}

	* > p:last-child {
		margin-bottom: 0 !important;
	}

	section, article {
		margin: 0 0 60px 0;
	}

	section:last-child, article:last-child {
		margin-bottom: 0;
	}

	.agGridDiv {
		height : 600px;
		width : 100%;
	}

/* Container */

	.container {
		margin: 0 auto;
		max-width: 100%;
		width: 1200px;
	}

		@media screen and (max-width: 1680px) {

			.container {
				width: 1200px;
			}

		}

		@media screen and (max-width: 1280px) {

			.container {
				width: calc(100% - 70px);
			}

		}

		@media screen and (max-width: 980px) {

			.container {
				width: calc(100% - 100px);
			}

			.agGridDiv {
				height : 450px;
				width : 100%;
			}

		}

		@media screen and (max-width: 736px) {

			.container {
				width: calc(100% - 40px);
			}

			.agGridDiv {
				height : 350px;
				width : 100%;
			}

		}

/* Row */

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: flex-start;
	}

		.row > * {
			box-sizing: border-box;
		}

		.row.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		.row.aln-left {
			justify-content: flex-start;
		}

		.row.aln-center {
			justify-content: center;
		}

		.row.aln-right {
			justify-content: flex-end;
		}

		.row.aln-top {
			align-items: flex-start;
		}

		.row.aln-middle {
			align-items: center;
		}

		.row.aln-bottom {
			align-items: flex-end;
		}

		.row > .imp {
			order: -1;
		}

		.row > .col-1 {
			width: 8.33333%;
		}

		.row > .off-1 {
			margin-left: 8.33333%;
		}

		.row > .col-2 {
			width: 16.66667%;
		}

		.row > .off-2 {
			margin-left: 16.66667%;
		}

		.row > .col-3 {
			width: 25%;
		}

		.row > .off-3 {
			margin-left: 25%;
		}

		.row > .col-4 {
			width: 33.33333%;
		}

		.row > .off-4 {
			margin-left: 33.33333%;
		}

		.row > .col-5 {
			width: 41.66667%;
		}

		.row > .off-5 {
			margin-left: 41.66667%;
		}

		.row > .col-6 {
			width: 50%;
		}

		.row > .off-6 {
			margin-left: 50%;
		}

		.row > .col-7 {
			width: 58.33333%;
		}

		.row > .off-7 {
			margin-left: 58.33333%;
		}

		.row > .col-8 {
			width: 66.66667%;
		}

		.row > .off-8 {
			margin-left: 66.66667%;
		}

		.row > .col-9 {
			width: 75%;
		}

		.row > .off-9 {
			margin-left: 75%;
		}

		.row > .col-10 {
			width: 83.33333%;
		}

		.row > .off-10 {
			margin-left: 83.33333%;
		}

		.row > .col-11 {
			width: 91.66667%;
		}

		.row > .off-11 {
			margin-left: 91.66667%;
		}

		.row > .col-12 {
			width: 100%;
		}

		.row > .off-12 {
			margin-left: 100%;
		}

		.row.gtr-0 {
			margin-top: 0px;
			margin-left: 0px;
		}

			.row.gtr-0 > * {
				padding: 0px 0 0 0px;
			}

			.row.gtr-0.gtr-uniform {
				margin-top: 0px;
			}

				.row.gtr-0.gtr-uniform > * {
					padding-top: 0px;
				}

		.row.gtr-25 {
			margin-top: -12.5px;
			margin-left: -12.5px;
		}

			.row.gtr-25 > * {
				padding: 12.5px 0 0 12.5px;
			}

			.row.gtr-25.gtr-uniform {
				margin-top: -12.5px;
			}

				.row.gtr-25.gtr-uniform > * {
					padding-top: 12.5px;
				}

		.row.gtr-50 {
			margin-top: -25px;
			margin-left: -25px;
		}

			.row.gtr-50 > * {
				padding: 25px 0 0 25px;
			}

			.row.gtr-50.gtr-uniform {
				margin-top: -25px;
			}

				.row.gtr-50.gtr-uniform > * {
					padding-top: 25px;
				}

		.row {
			margin-top: -50px;
			margin-left: -50px;
		}

			.row > * {
				padding: 50px 0 0 50px;
			}

			.row.gtr-uniform {
				margin-top: -50px;
			}

				.row.gtr-uniform > * {
					padding-top: 50px;
				}

		.row.gtr-150 {
			margin-top: -75px;
			margin-left: -75px;
		}

			.row.gtr-150 > * {
				padding: 75px 0 0 75px;
			}

			.row.gtr-150.gtr-uniform {
				margin-top: -75px;
			}

				.row.gtr-150.gtr-uniform > * {
					padding-top: 75px;
				}

		.row.gtr-200 {
			margin-top: -100px;
			margin-left: -100px;
		}

			.row.gtr-200 > * {
				padding: 100px 0 0 100px;
			}

			.row.gtr-200.gtr-uniform {
				margin-top: -100px;
			}

				.row.gtr-200.gtr-uniform > * {
					padding-top: 100px;
				}

		@media screen and (max-width: 1680px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-xlarge {
					order: -1;
				}

				.row > .col-1-xlarge {
					width: 8.33333%;
				}

				.row > .off-1-xlarge {
					margin-left: 8.33333%;
				}

				.row > .col-2-xlarge {
					width: 16.66667%;
				}

				.row > .off-2-xlarge {
					margin-left: 16.66667%;
				}

				.row > .col-3-xlarge {
					width: 25%;
				}

				.row > .off-3-xlarge {
					margin-left: 25%;
				}

				.row > .col-4-xlarge {
					width: 33.33333%;
				}

				.row > .off-4-xlarge {
					margin-left: 33.33333%;
				}

				.row > .col-5-xlarge {
					width: 41.66667%;
				}

				.row > .off-5-xlarge {
					margin-left: 41.66667%;
				}

				.row > .col-6-xlarge {
					width: 50%;
				}

				.row > .off-6-xlarge {
					margin-left: 50%;
				}

				.row > .col-7-xlarge {
					width: 58.33333%;
				}

				.row > .off-7-xlarge {
					margin-left: 58.33333%;
				}

				.row > .col-8-xlarge {
					width: 66.66667%;
				}

				.row > .off-8-xlarge {
					margin-left: 66.66667%;
				}

				.row > .col-9-xlarge {
					width: 75%;
				}

				.row > .off-9-xlarge {
					margin-left: 75%;
				}

				.row > .col-10-xlarge {
					width: 83.33333%;
				}

				.row > .off-10-xlarge {
					margin-left: 83.33333%;
				}

				.row > .col-11-xlarge {
					width: 91.66667%;
				}

				.row > .off-11-xlarge {
					margin-left: 91.66667%;
				}

				.row > .col-12-xlarge {
					width: 100%;
				}

				.row > .off-12-xlarge {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -12.5px;
					margin-left: -12.5px;
				}

					.row.gtr-25 > * {
						padding: 12.5px 0 0 12.5px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -12.5px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 12.5px;
						}

				.row.gtr-50 {
					margin-top: -25px;
					margin-left: -25px;
				}

					.row.gtr-50 > * {
						padding: 25px 0 0 25px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -25px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 25px;
						}

				.row {
					margin-top: -50px;
					margin-left: -50px;
				}

					.row > * {
						padding: 50px 0 0 50px;
					}

					.row.gtr-uniform {
						margin-top: -50px;
					}

						.row.gtr-uniform > * {
							padding-top: 50px;
						}

				.row.gtr-150 {
					margin-top: -75px;
					margin-left: -75px;
				}

					.row.gtr-150 > * {
						padding: 75px 0 0 75px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -75px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 75px;
						}

				.row.gtr-200 {
					margin-top: -100px;
					margin-left: -100px;
				}

					.row.gtr-200 > * {
						padding: 100px 0 0 100px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -100px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 100px;
						}

		}

		@media screen and (max-width: 1280px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-large {
					order: -1;
				}

				.row > .col-1-large {
					width: 8.33333%;
				}

				.row > .off-1-large {
					margin-left: 8.33333%;
				}

				.row > .col-2-large {
					width: 16.66667%;
				}

				.row > .off-2-large {
					margin-left: 16.66667%;
				}

				.row > .col-3-large {
					width: 25%;
				}

				.row > .off-3-large {
					margin-left: 25%;
				}

				.row > .col-4-large {
					width: 33.33333%;
				}

				.row > .off-4-large {
					margin-left: 33.33333%;
				}

				.row > .col-5-large {
					width: 41.66667%;
				}

				.row > .off-5-large {
					margin-left: 41.66667%;
				}

				.row > .col-6-large {
					width: 50%;
				}

				.row > .off-6-large {
					margin-left: 50%;
				}

				.row > .col-7-large {
					width: 58.33333%;
				}

				.row > .off-7-large {
					margin-left: 58.33333%;
				}

				.row > .col-8-large {
					width: 66.66667%;
				}

				.row > .off-8-large {
					margin-left: 66.66667%;
				}

				.row > .col-9-large {
					width: 75%;
				}

				.row > .off-9-large {
					margin-left: 75%;
				}

				.row > .col-10-large {
					width: 83.33333%;
				}

				.row > .off-10-large {
					margin-left: 83.33333%;
				}

				.row > .col-11-large {
					width: 91.66667%;
				}

				.row > .off-11-large {
					margin-left: 91.66667%;
				}

				.row > .col-12-large {
					width: 100%;
				}

				.row > .off-12-large {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -8.75px;
					margin-left: -8.75px;
				}

					.row.gtr-25 > * {
						padding: 8.75px 0 0 8.75px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -8.75px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 8.75px;
						}

				.row.gtr-50 {
					margin-top: -17.5px;
					margin-left: -17.5px;
				}

					.row.gtr-50 > * {
						padding: 17.5px 0 0 17.5px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -17.5px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 17.5px;
						}

				.row {
					margin-top: -35px;
					margin-left: -35px;
				}

					.row > * {
						padding: 35px 0 0 35px;
					}

					.row.gtr-uniform {
						margin-top: -35px;
					}

						.row.gtr-uniform > * {
							padding-top: 35px;
						}

				.row.gtr-150 {
					margin-top: -52.5px;
					margin-left: -52.5px;
				}

					.row.gtr-150 > * {
						padding: 52.5px 0 0 52.5px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -52.5px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 52.5px;
						}

				.row.gtr-200 {
					margin-top: -70px;
					margin-left: -70px;
				}

					.row.gtr-200 > * {
						padding: 70px 0 0 70px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -70px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 70px;
						}

		}

		@media screen and (max-width: 1050px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-medium {
					order: -1;
				}

				.row > .col-1-medium {
					width: 8.33333%;
				}

				.row > .off-1-medium {
					margin-left: 8.33333%;
				}

				.row > .col-2-medium {
					width: 16.66667%;
				}

				.row > .off-2-medium {
					margin-left: 16.66667%;
				}

				.row > .col-3-medium {
					width: 25%;
				}

				.row > .off-3-medium {
					margin-left: 25%;
				}

				.row > .col-4-medium {
					width: 33.33333%;
				}

				.row > .off-4-medium {
					margin-left: 33.33333%;
				}

				.row > .col-5-medium {
					width: 41.66667%;
				}

				.row > .off-5-medium {
					margin-left: 41.66667%;
				}

				.row > .col-6-medium {
					width: 50%;
				}

				.row > .off-6-medium {
					margin-left: 50%;
				}

				.row > .col-7-medium {
					width: 58.33333%;
				}

				.row > .off-7-medium {
					margin-left: 58.33333%;
				}

				.row > .col-8-medium {
					width: 66.66667%;
				}

				.row > .off-8-medium {
					margin-left: 66.66667%;
				}

				.row > .col-9-medium {
					width: 75%;
				}

				.row > .off-9-medium {
					margin-left: 75%;
				}

				.row > .col-10-medium {
					width: 83.33333%;
				}

				.row > .off-10-medium {
					margin-left: 83.33333%;
				}

				.row > .col-11-medium {
					width: 91.66667%;
				}

				.row > .off-11-medium {
					margin-left: 91.66667%;
				}

				.row > .col-12-medium {
					width: 100%;
				}

				.row > .off-12-medium {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -12.5px;
					margin-left: -12.5px;
				}

					.row.gtr-25 > * {
						padding: 12.5px 0 0 12.5px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -12.5px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 12.5px;
						}

				.row.gtr-50 {
					margin-top: -25px;
					margin-left: -25px;
				}

					.row.gtr-50 > * {
						padding: 25px 0 0 25px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -25px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 25px;
						}

				.row {
					margin-top: -50px;
					margin-left: -50px;
				}

					.row > * {
						padding: 50px 0 0 50px;
					}

					.row.gtr-uniform {
						margin-top: -50px;
					}

						.row.gtr-uniform > * {
							padding-top: 50px;
						}

				.row.gtr-150 {
					margin-top: -75px;
					margin-left: -75px;
				}

					.row.gtr-150 > * {
						padding: 75px 0 0 75px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -75px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 75px;
						}

				.row.gtr-200 {
					margin-top: -100px;
					margin-left: -100px;
				}

					.row.gtr-200 > * {
						padding: 100px 0 0 100px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -100px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 100px;
						}

		}

		@media screen and (max-width: 736px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-small {
					order: -1;
				}

				.row > .col-1-small {
					width: 8.33333%;
				}

				.row > .off-1-small {
					margin-left: 8.33333%;
				}

				.row > .col-2-small {
					width: 16.66667%;
				}

				.row > .off-2-small {
					margin-left: 16.66667%;
				}

				.row > .col-3-small {
					width: 25%;
				}

				.row > .off-3-small {
					margin-left: 25%;
				}

				.row > .col-4-small {
					width: 33.33333%;
				}

				.row > .off-4-small {
					margin-left: 33.33333%;
				}

				.row > .col-5-small {
					width: 41.66667%;
				}

				.row > .off-5-small {
					margin-left: 41.66667%;
				}

				.row > .col-6-small {
					width: 50%;
				}

				.row > .off-6-small {
					margin-left: 50%;
				}

				.row > .col-7-small {
					width: 58.33333%;
				}

				.row > .off-7-small {
					margin-left: 58.33333%;
				}

				.row > .col-8-small {
					width: 66.66667%;
				}

				.row > .off-8-small {
					margin-left: 66.66667%;
				}

				.row > .col-9-small {
					width: 75%;
				}

				.row > .off-9-small {
					margin-left: 75%;
				}

				.row > .col-10-small {
					width: 83.33333%;
				}

				.row > .off-10-small {
					margin-left: 83.33333%;
				}

				.row > .col-11-small {
					width: 91.66667%;
				}

				.row > .off-11-small {
					margin-left: 91.66667%;
				}

				.row > .col-12-small {
					width: 100%;
				}

				.row > .off-12-small {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -5px;
					margin-left: -5px;
				}

					.row.gtr-25 > * {
						padding: 5px 0 0 5px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -5px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 5px;
						}

				.row.gtr-50 {
					margin-top: -10px;
					margin-left: -10px;
				}

					.row.gtr-50 > * {
						padding: 10px 0 0 10px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -10px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 10px;
						}

				.row {
					margin-top: -20px;
					margin-left: -20px;
				}

					.row > * {
						padding: 20px 0 0 20px;
					}

					.row.gtr-uniform {
						margin-top: -20px;
					}

						.row.gtr-uniform > * {
							padding-top: 20px;
						}

				.row.gtr-150 {
					margin-top: -30px;
					margin-left: -30px;
				}

					.row.gtr-150 > * {
						padding: 30px 0 0 30px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -30px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 30px;
						}

				.row.gtr-200 {
					margin-top: -40px;
					margin-left: -40px;
				}

					.row.gtr-200 > * {
						padding: 40px 0 0 40px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -40px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 40px;
						}

		}

/* Reusable */

	article.blog-post {
		position: relative;
	}

		article.blog-post .comments {
			position: absolute;
			top: 0;
			right: 0;
			height: 32px;
			line-height: 24px;
			padding-left: 40px;
			background: url("images/icon-bubble.png") no-repeat;
		}

		article.blog-post .blog-post-image {
			width: 100%;
		}

	ul.small-image-list li {
		overflow: hidden;
		margin-bottom: 1.5em;
	}

		ul.small-image-list li img {
			width: 78px;
		}

	ul.big-image-list li {
		overflow: hidden;
		margin-bottom: 2em;
	}

		ul.big-image-list li img {
			width: 178px;
		}

	ul.link-list li {
		border-top: solid 1px #ced0b7;
		padding: 0.75em 0 0 0;
		margin: 0.75em 0 0 0;
	}

		ul.link-list li:first-child {
			padding-top: 0;
			margin-top: 0;
			border-top: 0;
		}

	.button {
		background-image: -moz-linear-gradient(top, #008dad, #007b9d);
		background-image: -webkit-linear-gradient(top, #008dad, #007b9d);
		background-image: -ms-linear-gradient(top, #008dad, #007b9d);
		background-image: linear-gradient(top, #008dad, #007b9d);
		display: inline-block;
		padding: 15px 25px 15px 25px;
		background-color: #007b9d;
		border-radius: 10px;
		text-transform: lowercase;
		text-decoration: none;
		color: #fff;
		font-size: 1.2em;
		letter-spacing: -1px;
		border: solid 1px #006e8b;
		box-shadow: inset 0px 0px 0px 1px #18a8c8;
	}

		.button:hover {
			background-image: -moz-linear-gradient(top, #1b9fbe, #118eb1);
			background-image: -webkit-linear-gradient(top, #1b9fbe, #118eb1);
			background-image: -ms-linear-gradient(top, #1b9fbe, #118eb1);
			background-image: linear-gradient(top, #1b9fbe, #118eb1);
			background-color: #118eb1;
			box-shadow: inset 0px 0px 0px 1px #3ecceb;
		}

/* Header */

	#header-wrapper {
		background-image: -moz-linear-gradient(top, #008dab, #007294);
		background-image: -webkit-linear-gradient(top, #008dab, #007294);
		background-image: -ms-linear-gradient(top, #008dab, #007294);
		background-image: linear-gradient(top, #008dab, #007294);
		background-color: #007294;
	}

	#header {
		background-image: -moz-linear-gradient(top, #008ead, #007b9d);
		background-image: -webkit-linear-gradient(top, #008ead, #007b9d);
		background-image: -ms-linear-gradient(top, #008ead, #007b9d);
		background-image: linear-gradient(top, #008ead, #007b9d);
		-moz-box-sizing: content-box;
		-webkit-box-sizing: content-box;
		-ms-box-sizing: content-box;
		box-sizing: content-box;
		position: relative;
		margin: 2.5% 0 2.5% 0;
		padding: 0 40px 0 40px;
		border-radius: 10px;
		border: solid 1px #006e8b;
		box-shadow: inset 0px 0px 0px 1px #12a0bf, 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
		background-color: #007b9d;
		height: 86px;
	}

		#header h1 {
			position: absolute;
			left: 40px;
			top: 0;
			color: #fff;
			font-size: 2.4em;
			letter-spacing: -2px;
			line-height: 86px;
		}

			#header h1 a {
				color: #fff;
				text-decoration: none;
			}

		#header nav {
			position: absolute;
			right: 40px;
			top: 1px;
			line-height: 84px;
			text-transform: lowercase;
			font-size: 1.3em;
			letter-spacing: -1px;
		}

			#header nav a {
				display: inline-block;
				text-decoration: none;
				color: #fff;
				padding: 0 25px 0 25px;
				outline: 0;
			}

				#header nav a.current-page-item {
					background-image: -moz-linear-gradient(top, #007b99, #007897);
					background-image: -webkit-linear-gradient(top, #007b99, #007897);
					background-image: -ms-linear-gradient(top, #007b99, #007897);
					background-image: linear-gradient(top, #007b99, #007897);
					background-color: #007897;
					box-shadow: inset 0px 1px 5px 1px rgba(0, 0, 0, 0.1), 0px 0px 5px 1px rgba(255, 255, 255, 0.1);
				}

				#header nav a:hover {
					background-image: -moz-linear-gradient(top, #0782a0, #077d9b);
					background-image: -webkit-linear-gradient(top, #0782a0, #077d9b);
					background-image: -ms-linear-gradient(top, #0782a0, #077d9b);
					background-image: linear-gradient(top, #0782a0, #077d9b);
					background-color: #0882a1;
					box-shadow: inset 0px 1px 5px 1px rgba(0, 0, 0, 0.05), 0px 0px 5px 1px rgba(255, 255, 255, 0.05);
				}

/* Banner */


	#banner {
		position: relative;
		background-image: url(images/beach.jpg);
		background-size: cover;
		text-align: center;
		width : calc(100% - 80px);
		margin : auto;
	}

		#banner h2 {
			font-size: 5em;
			text-align: center;
			letter-spacing: -2px;
			margin: 0;
		}

		#banner span {
			display: block;
			left: 0;
			margin: 0.5em 0 0 0;
			font-size: 2em;
			letter-spacing: -1px;
			opacity: 0.8;
		}

/* Main */

	#main {
		background: rgb(0,120,151);
		background: linear-gradient(180deg, rgba(0,120,151,0.8603816526610644) 0%, rgba(0,120,151,0.24413515406162467) 100%);
		padding: 4em 0;
		color : white;
	}

/* Footer */

	#footer-wrapper {
		padding: 2em 0;
	}

/* Copyright */

	#copyright {
		text-align: center;
		color: #A6A88F;
		border-top: solid 1px #ced0b7;
		padding: 3em 0 0 0;
		margin: 3em 0 0 0;
	}

		#copyright a {
			color: #A6A88F;
		}

/* Large */

	@media screen and (max-width: 1280px) {

		/* Reusable */

			ul.small-image-list img {
				width: 50px;
			}

			ul.big-image-list img {
				width: 75px;
			}

		/* Header */

			#header {
				height: 76px;
			}

				#header h1 {
					font-size: 2em;
					line-height: 76px;
				}

				#header nav {
					line-height: 74px;
					font-size: 1.1em;
				}

					#header nav a {
						padding: 0 20px 0 20px;
					}

		/* Banner */

			#banner {
				width: 100%;
				text-align: center;
				margin : auto;
				padding: 5em 0;
			}

				#banner h2 {
					font-size: 5em;
				}

				#banner span {
					margin-top : 50px;
					font-size: 2em;
				}

		/* Footer */

			#footer-wrapper {
				padding: 2em 0;
			}

		/* Copyright */

			#copyright {
				padding: 3em 0 0 0;
				margin: 4em 0 0 0;
			}

	}

/* Small */

	#navPanel, #titleBar {
		display: none;
	}

	@media screen and (max-width: 980px) {

		/* Basic */

			html, body {
				overflow-x: hidden;
			}

		/* Page Wrapper */

			#page-wrapper {
				padding-top: 44px;
			}

		/* Header */

			#header-wrapper {
				background-image: -moz-linear-gradient(top, #008dab, #007294);
				background-image: -webkit-linear-gradient(top, #008dab, #007294);
				background-image: -ms-linear-gradient(top, #008dab, #007294);
				background-image: linear-gradient(top, #008dab, #007294);
				background-color: #007294;
			}

			#header {
				display: none;
			}

			#banner {
				padding: 6em 0;
			}

			#banner h2 {
				font-size: 4em;
			}

			#banner span {
				font-size: 2em;
			}

		/* Nav */

			#page-wrapper {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				padding-bottom: 1px;
			}

			#titleBar {
				background-image: -moz-linear-gradient(top, #008dab, #007294);
				background-image: -webkit-linear-gradient(top, #008dab, #007294);
				background-image: -ms-linear-gradient(top, #008dab, #007294);
				background-image: linear-gradient(top, #008dab, #007294);
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				display: block;
				height: 44px;
				left: 0;
				position: fixed;
				top: 0;
				width: 100%;
				z-index: 1001;
				text-align: center;
				color: #fff;
				font-size: 1.25em;
				background-color: #007294;
			}

				#titleBar .title {
					line-height: 44px;
				}

				#titleBar .toggle {
					position: absolute;
					top: 0;
					left: 0;
					width: 80px;
					height: 60px;
				}

					#titleBar .toggle:after {
						content: '';
						position: absolute;
						left: 4px;
						top: 4px;
						color: #fff;
						text-align: center;
						line-height: 31px;
						font-size: 0.8em;
						width: 50px;
						height: 35px;
						border-radius: 5px;
						box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.25), inset 0px 1px 2px 0px rgba(0, 0, 0, 0.5), inset 0px 6px 13px 0px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.1);
					}

					#titleBar .toggle:before {
						content: '';
						position: absolute;
						width: 20px;
						height: 30px;
						background: url("images/mobileUI-site-nav-opener-bg.svg");
						top: 16px;
						left: 19px;
					}

					#titleBar .toggle:active:after {
						background: rgba(0, 0, 0, 0.2);
					}

			#navPanel {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transform: translateX(-275px);
				-webkit-transform: translateX(-275px);
				-ms-transform: translateX(-275px);
				transform: translateX(-275px);
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				display: block;
				height: 100%;
				left: 0;
				overflow-y: auto;
				position: fixed;
				top: 0;
				width: 275px;
				z-index: 10002;
				background: #00536F;
				color: #fff;
				box-shadow: inset -10px 0px 40px 0px rgba(0, 0, 0, 0.5);
			}

				#navPanel .link {
					display: block;
					color: #fff;
					border-top: solid 1px rgba(255, 255, 255, 0.1);
					border-bottom: solid 1px rgba(0, 0, 0, 0.2);
					height: 55px;
					line-height: 55px;
					padding: 0 15px 0 15px;
					text-decoration: none;
				}

					#navPanel .link:first-child {
						border-top: 0;
					}

					#navPanel .link:last-child {
						border-bottom: 0;
					}

			body.navPanel-visible #page-wrapper {
				-moz-transform: translateX(275px);
				-webkit-transform: translateX(275px);
				-ms-transform: translateX(275px);
				transform: translateX(275px);
			}

			body.navPanel-visible #titleBar {
				-moz-transform: translateX(275px);
				-webkit-transform: translateX(275px);
				-ms-transform: translateX(275px);
				transform: translateX(275px);
			}

			body.navPanel-visible #navPanel {
				-moz-transform: translateX(0);
				-webkit-transform: translateX(0);
				-ms-transform: translateX(0);
				transform: translateX(0);
			}

	}

/* Small */

	@media screen and (max-width: 736px) {

		/* Basic */

			body {
				font-size: 13pt;
			}

			h2 {
				font-size: 1.4em;
			}

			h3 {
				font-size: 1.2em;
			}

			h4 {
				font-size: 1em;
			}

		/* Reusable */

			ul.small-image-list li img {
				width: 100px;
			}

			ul.big-image-list li img {
				width: 100px;
			}

			ul.link-list {
				margin: 0 0 1em 0;
			}

			article.blog-post .comments {
				display: block;
				position: relative;
				top: -0.5em;
				margin: 0 0 0.5em 0;
			}

			.button {
				font-size: 1em;
			}

			.blog-post-image {
				width: 100%;
			}

		/* Banner */

			#banner-wrapper {
				background: #c8d2bc url(images/bg1.png);
				padding: 20px 0;
			}

			#banner {
				box-shadow: inset 0 0 0 10px #fff;
				padding: 4em 1em;
			}

				#banner h2 {
					font-size: 3em;
					letter-spacing: -2px;
					line-height: 1.25em;
				}

				#banner span {
					font-size: 1.5em;
					letter-spacing: -1px;
					line-height: 1.25em;
				}

		/* Main */

			#main {
				padding: 40px 0;
			}

		/* Footer */

			#footer-wrapper {
				padding: 20px 0;
			}

		/* Copyright */

			#copyright {
				text-align: center;
				color: #A6A88F;
				padding: 30px 0 0 0;
				font-size: 0.8em;
				line-height: 1.5em;
			}

				#copyright a {
					color: #A6A88F;
				}
	}

	.card {
		background :#c9d5bb;
		border-radius : 25px;
		border : 2px solid white;
		padding : 15px;
	}

	.card .bio {
		font-family: 'Edu TAS Beginner', sans-serif;
		font-size: 22px;
		margin-bottom: 20px;
		color : black;
	}

	.card .bio b {
		font-size: 28px;
	}

	.card .bio ul {
		margin-left : 30px;
	}

	.card .bio ul li{
		list-style-type: circle;
	}

	.card .bio p{
		margin-bottom: 5px;
	}
	.card img.course {
		height : 200px;
		width : 100%;
		object-fit: cover;
	}

	.instructor {
		position: relative;
		margin-bottom : 50px;
	}

	.instructor .header {
		text-transform: capitalize;
		text-align : center;
		margin-bottom: 20px;
	}

	.instructor .header img {
		margin-bottom: 20px;
	}

	.instructor h1 {
		font-size : 24px;
	}

	.instructor h2 {
		font-size : 18px;
	}

	.instructor .courses {
		margin-left : 10px;
		line-height: 30px;
	}

	.course {
		position: relative;
		margin-bottom : 50px;
	}

	.course .card img.course {
		width: 100%;
		padding : 0;
		margin-bottom: 20px;
		border : 3px solid white;
		border-radius : 3px;
	}

	.course h1 {
		font-size : 24px;
		margin-bottom: 20px;
	}

	.course .instructor-avatar {
		position : absolute;
		top : 15px;
		left : 15px;
	}

	.course .link {
		text-align : right;
	}

	.course h2 {
		font-size : 20px;
	}


	.avatar {
		height : 250px;
		width : 250px;
		border-radius: 50%;
		object-fit:cover;
		overflow : hidden;
		border: 6px solid rgba(0, 120, 151);
		box-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
	}

	.avatar-small {
		height : 75px;
		width : 75px;
		border-radius: 50%;
		object-fit:cover;
		overflow : hidden;
		border: 3px solid rgba(0, 120, 151);
		box-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
	}

	.popupNav {
		background-color : rgba(0, 120, 151);
		padding: 30px;
		padding-top: 70px;
		display : flex;
		flex-direction: column;
		height : 100%;
	}

	.popupNav a {
		padding: 20px;
		color : white;
		text-decoration: none;
		font-size: 24px;
	}

	.nav-icon {
		margin-bottom : -5px;
		margin-right : 5px;
	}

	.white-panel {
		background : rgba(255,255,255,0.6);
		padding : 30px;
		color : rgba(0, 120, 151);
	}

	.form {
		margin : 15px;
	}

	.form .form-item {
		margin-bottom : 20px;
	}

	.form .send-button {
		width : 150px;
	}

	.logo {
		margin-top: -20px;
	}

	.large {
		font-size : 24px;
	}

	.large em {
		font-weight : 700;
	}
	
	#footer-wrapper {
		text-align: right;
		padding-right : 50px;
	}
	
	a.social {
		text-decoration: none;
	}